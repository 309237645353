<template>

<a :href="url" class="item" target="_blank">

	<app-icon :icon="icon" class="item-icon" v-if="icon" />
	
	<div class="item-text">{{ text }}</div>

	<app-icon :icon="iconAfter" class="item-icon is-after" />

</a>

</template>

<script>

export default {

	props: ['text', 'url', 'icon', 'iconAfter']

}

</script>

<style scoped>

.item {
	display: flex;
	flex-direction: row;
	min-height: 40px;
	align-items: center;
	color: #bfc4cb;
	padding: 0px 20px;
	cursor: pointer;
}

.is-mobile .item {
	padding: 0px 20px;
}

.item:hover {
	background-color: #626e7f;
	color: #fff;
}

.item.is-active {
	background-color: #ffbe7a;
	color: #4d5b6e;
}

.item-icon {
	width: 32px;
	text-align: center;
	flex-shrink: 0;
	font-size: 20px;
	margin-right: 10px;
}

.item-icon.is-after {
	text-align: right;
	margin-right: 0px;
}

.is-mobile .item-icon {
	font-size: 20px;
}

.item-text {
	font-size: 16px;
	font-weight: 300;
	flex-grow: 1;
	letter-spacing: 1px;
}

.is-mobile .item-text {
	font-size: 14px;
}

.item-count {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 4px;
	text-align: center;
	background-color: #687589;
	font-weight: 400;
	line-height: 24px;
	color: #b4b8bc;
}

.item-count.is-highlight {
	background-color: #dd4848;
	color: #fff;
}

.item.is-active .item-count {
	background-color: #fff;
	color: #333;
}

</style>
